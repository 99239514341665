import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../assets/css/profile.css';
import { CDN_URL } from '../constants/Constants';
import Gallery from '../utils/Gallery';

class Profile extends Component {
    render() {
        const { data: p, handleDownload, authed } = this.props;
        const name = p.name.length > 0 ? `${p.name} (${p.mid})` : p.mid;
        const hasSubscription = authed.authJWT && authed.authJWT.subscription_id && authed.authJWT.subscription_id.length > 0;
        return (
            <ul className="clearfix list-unstyled profile-list">
                <li className="row">
                    <div className="col-md-3 col-sm-4">
                        <div className="carousel slide">
                            <div className="carousel-inner" role="listbox">
                                <div className="item active">
                                    <figure style={{ height: 340 }} className="thumb-image">
                                        <img
                                            ref={i => (this.img = i)}
                                            src={
                                                p.photos.length > 0
                                                    ? `${CDN_URL}${p.photos[0].large}`
                                                    : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&f=y&s=165'
                                            }
                                            onError={() => {
                                                this.img.onerror = null;
                                                this.img.src = p.photos.length > 0 ? `${CDN_URL}${p.photos[0].url}` : '';
                                            }}
                                            alt="Profile"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-8">
                        <div className="clearfix">
                            <h2>{name}</h2>
                            <ul className="list-inline first-list">
                                <li className="col-md-4">
                                    <strong>{p.age}yrs</strong>
                                    <p>Age</p>
                                </li>
                                <li className="col-md-4">
                                    <strong>{p.personal.height_ft}</strong>
                                    <p>Height</p>
                                </li>
                                <li className="col-md-4">
                                    <strong>{p.surname}</strong>
                                    <p>Surname</p>
                                </li>
                                <li className="col-md-4">
                                    <strong>{p.community}</strong>
                                    <p>Community</p>
                                </li>
                            </ul>
                            <ul className="list-inline">
                                <li className="col-md-4">
                                    <strong>{p.horoscope.birth_star}</strong>
                                    <p>Birth Star</p>
                                </li>
                                <li className="col-md-4">
                                    <strong>{p.city}</strong>
                                    <p>City</p>
                                </li>
                                <li className="col-md-4">
                                    <strong>{p.work.education ? p.work.education : '-'}</strong>
                                    <p>Education</p>
                                </li>
                                <li className="col-md-4">
                                    <strong>{p.work.position}</strong>
                                    <p>Occupation</p>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="pull-right"
                            style={{
                                paddingRight: 15,
                                paddingTop: 25,
                                paddingBottom: 25,
                            }}
                        >
                            {p.mobiles && p.mobiles.length > 0 ? (
                                <div className="btn-group">
                                    {p.mobiles
                                        .filter(m => m.length > 0)
                                        .map((m, i) => (
                                            <a key={i} href={`tel:${m}`} className="btn btn-default btn-lg">
                                                <i className="fa fa-phone fa-lg" /> {m}
                                            </a>
                                        ))}
                                </div>
                            ) : (
                                <a
                                    className="btn btn-info btn-lg"
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        return handleDownload();
                                    }}
                                    role="button"
                                >
                                    {hasSubscription ? 'View Contact Details' : 'Upgrade to View Contact'}
                                </a>
                            )}
                        </div>
                    </div>
                </li>
                <li className="row">
                    <div className="profile-detail">
                        <h4>Detailed Profile</h4>
                        <Gallery
                            images={p.photos.map(({ caption, large, short }) => ({
                                src: CDN_URL + large,
                                thumbnail: CDN_URL + short,
                                srcset: [],
                                caption,
                                orientation: 'landscape',
                                useForDemo: true,
                            }))}
                            showThumbnails
                        />
                        <ul className="row list-unstyled">
                            <li className="col-sm-6">
                                <div className="clearfix">
                                    <div className="detail-header">
                                        <figure className="green">
                                            <i className="icon icon-user" />
                                        </figure>
                                        <h6>Personal</h6>
                                    </div>
                                    <ul className="detail-body list-unstyled">
                                        <li>
                                            <span>Height</span>
                                            <span>{p.personal.height_ft}</span>
                                        </li>
                                        <li>
                                            <span>Complexion</span>
                                            <span>{p.personal.complexion}</span>
                                        </li>
                                        <li>
                                            <span>Body Type</span>
                                            <span>{p.personal.body_type}</span>
                                        </li>
                                        <li>
                                            <span>Physical Status</span>
                                            <span>{p.personal.physical_status}</span>
                                        </li>
                                        <li>
                                            <span>Marital Status</span>
                                            <span>{p.personal.marital_status}</span>
                                        </li>
                                        <li>
                                            <span>Food Habits</span>
                                            <span>{p.personal.food_habits}</span>
                                        </li>
                                        <li>
                                            <span>About</span>
                                            <span>{p.personal.about}</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="col-sm-6">
                                <div className="clearfix">
                                    <div className="detail-header">
                                        <figure className="orange">
                                            <i className="icon icon-view" />
                                        </figure>
                                        <h6>Family</h6>
                                    </div>
                                    <ul className="detail-body list-unstyled">
                                        <li>
                                            <span>Father's Name</span>
                                            <span>{p.family.father_name}</span>
                                        </li>
                                        <li>
                                            <span>Mother's Name</span>
                                            <span>{p.family.mother_name}</span>
                                        </li>
                                        <li>
                                            <span>Father's Occupation</span>
                                            <span>{p.family.father_occupation}</span>
                                        </li>
                                        <li>
                                            <span>Mother's Occupation</span>
                                            <span>{p.family.mother_occupation}</span>
                                        </li>
                                        <li>
                                            <span>Sisters</span>
                                            <span>
                                                {p.family.sisters > 0
                                                    ? p.family.sisters
                                                      + (p.family.sisters_married > 0 ? ` (${p.family.sisters_married} married)` : ' (None married)')
                                                    : 'None'}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Brothers</span>
                                            <span>
                                                {p.family.brothers > 0
                                                    ? p.family.brothers
                                                      + (p.family.brothers_married > 0 ? ` (${p.family.brothers_married} married)` : ' (None married)')
                                                    : 'None'}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Family Status</span>
                                            <span>{p.family.family_status}</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="col-sm-6">
                                <div className="clearfix">
                                    <div className="detail-header">
                                        <figure className="red">
                                            <i className="icon icon-degree" />
                                        </figure>
                                        <h6>Education / Work</h6>
                                    </div>
                                    <ul className="detail-body list-unstyled">
                                        <li>
                                            <span>Education</span>
                                            <span>{p.work.education}</span>
                                        </li>
                                        <li>
                                            <span>Education Desc</span>
                                            <span>{p.work.education_desc}</span>
                                        </li>
                                        <li>
                                            <span>Profession</span>
                                            <span>{p.work.profession}</span>
                                        </li>
                                        <li>
                                            <span>Position</span>
                                            <span>{p.work.position}</span>
                                        </li>
                                        <li>
                                            <span>Annual Income</span>
                                            <span>{p.work.annual_income}</span>
                                        </li>
                                        <li>
                                            <span>Work Location</span>
                                            <span>{p.work.work_location}</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="col-sm-6">
                                <div className="clearfix">
                                    <div className="detail-header">
                                        <figure className="purple">
                                            <i className="icon icon-award" />
                                        </figure>
                                        <h6>Astro</h6>
                                    </div>
                                    <ul className="detail-body list-unstyled">
                                        <li>
                                            <span>Raasi</span>
                                            <span>{p.horoscope.raasi}</span>
                                        </li>
                                        <li>
                                            <span>Birth Star</span>
                                            <span>{p.horoscope.birth_star}</span>
                                        </li>
                                        <li>
                                            <span>Padham</span>
                                            <span>{p.horoscope.padham}</span>
                                        </li>
                                        <li>
                                            <span>Birth Time</span>
                                            <span>
                                                {p.horoscope.birth_time && p.horoscope.birth_time.length > 0 ? (
                                                    `${new Date(p.dob).toLocaleDateString('en-US', {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                    })} - ${p.horoscope.birth_time}`
                                                ) : (
                                                    <a
                                                        href="/"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            return handleDownload();
                                                        }}
                                                    >
                                                        {hasSubscription ? 'Upgrade to view Birth Time' : 'View Birth Time'}
                                                    </a>
                                                )}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Birth Place</span>
                                            <span>{p.horoscope.birth_place}</span>
                                        </li>

                                        <li>
                                            <span>Horoscope</span>
                                            <span>
                                                {p.attachments && p.attachments.length > 0 ? (
                                                    p.attachments.map((a, i) => (
                                                        <a key={i} target="_blank" rel="noopener noreferrer" href={`${CDN_URL}${a.url}`}>
                                                            Download
                                                        </a>
                                                    ))
                                                ) : (
                                                    <a
                                                        href="/"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            return handleDownload();
                                                        }}
                                                    >
                                                        {hasSubscription ? 'Upgrade to view Horoscope' : 'View Horoscope'}
                                                    </a>
                                                )}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        );
    }
}

const reduxConnector = connect();

export default reduxConnector(Profile);
