import React from 'react';
import dayjs from 'dayjs';

const Footer = () => (
    <footer>
        <div className="container text-center">
            <div className="row" style={{ paddingBottom: 10 }}>
                <ul className="list-inline">
                    <li>
                        <a href="/view/terms">Terms & Conditions</a>
                    </li>
                    <li>
                        <a href="/view/privacy">Privacy Policy</a>
                    </li>
                </ul>
            </div>
            <div className="row" style={{ paddingBottom: 10 }}>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/groups/tn.marathi.friends/"
                    style={{ marginRight: 10 }}
                    className="social-icon-link"
                >
                    <i className="fa fa-facebook fa-lg" />
                </a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/marathimala"
                    className="social-icon-link"
                >
                    <i className="fa fa-instagram fa-lg" />
                </a>
            </div>

            <div className="row">
                &copy; {dayjs().format('YYYY')}{' '}
                <a href="http://www.marathimala.com">Marathimala</a>
            </div>
        </div>
    </footer>
);

export default Footer;
