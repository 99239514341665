import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import assign from 'lodash/assign';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import * as types from '../constants/ActionTypes';
import { PROFILE_STATUS } from '../constants/Constants';

const initialState = {
    profileList: { count: 0, rows: [] },
    profileListPage: 1,
    profileListFilter: [
        {
            name: 'domain',
            value: 'marathimala',
            isChecked: true,
            isMulti: false,
        },
    ],
};

const replaceArray = (collection, identity, replacement) => {
    const index = indexOf(collection, find(collection, identity));
    collection.splice(index, 1, replacement);
    return collection;
};

export default function admin(state = initialState, action) {
    switch (action.type) {
        case types.ADMIN_PROFILE_LIST:
            return Object.assign({}, state, {
                profileList: {
                    count: action.data.count,
                    rows:
                        parseInt(action.page, 10) > 1
                            ? [...state.profileList.rows, ...action.data.rows]
                            : action.data.rows,
                },
                isFetching: false,
            });
        case types.ADMIN_PROFILE_LIST_VIEW_MORE:
            return Object.assign({}, state, {
                profileListPage: ++state.profileListPage,
            });
        case types.ADMIN_PROFILE_LIST_FILTER:
            return Object.assign({}, state, {
                profileListFilter: filter(
                    uniqBy(
                        [action.profileListFilter, ...state.profileListFilter],
                        (o) => (o.isMulti ? `${o.name}|${o.value}` : o.name),
                    ),
                    { isChecked: true },
                ),
                profileListPage: 1,
            });
        case types.ADMIN_PROFILE_LIST_FILTER_RESET:
            return Object.assign({}, state, {
                profileListFilter: [],
                profileListPage: 1,
            });
        case types.ADMIN_FETCHING_DATA:
            return Object.assign({}, state, {
                isFetching: action.status,
            });
        case types.ADMIN_PROFILE_STATUS_UPDATE:
            return Object.assign({}, state, {
                profileList: {
                    ...state.profileList,
                    rows: replaceArray(
                        [...state.profileList.rows],
                        { profile_id: action.profileId },
                        assign(
                            find(state.profileList.rows, {
                                profile_id: action.profileId,
                            }),
                            {
                                status: action.status,
                                status_label: PROFILE_STATUS.find(
                                    (s) => s.value === action.status,
                                ).label,
                            },
                        ),
                    ),
                },
                isFetching: false,
            });
        case types.ADMIN_PROFILE_FEATURED_UPDATE:
            return Object.assign({}, state, {
                profileList: {
                    ...state.profileList,
                    rows: replaceArray(
                        [...state.profileList.rows],
                        { profile_id: action.profileId },
                        assign(
                            find(state.profileList.rows, {
                                profile_id: action.profileId,
                            }),
                            {
                                is_featured: action.status,
                            },
                        ),
                    ),
                },
                isFetching: false,
            });
        default:
            return state;
    }
}
