import React from 'react';
import { connect } from 'react-redux';

import Pricing from './Pricing';

import '../assets/css/pricing.css';

const BRIDE_PRICING = [
    {
        price: '1300',
        label: 'Silver',
        paymentLink: 'https://www.instamojo.com/@marathimala/l19bf452fc86945ec9f3a876df3c5e67e/',
        features: [
            {
                value: '10',
                units: 'profiles',
            },
            {
                value: '90',
                units: 'days',
            },
        ],
    },
    {
        price: '2600',
        label: 'Gold',
        paymentLink: 'https://www.instamojo.com/@marathimala/l145557de27d54dbebfe052182b26fe95/',
        features: [
            {
                value: '40',
                units: 'profiles',
            },
            {
                value: '180',
                units: 'days',
            },
        ],
    },

    {
        price: '4700',
        label: 'Platinum',
        paymentLink: 'https://www.instamojo.com/@marathimala/l5cb3aa754d794fa0a029eac95a6147a9/',
        features: [
            {
                value: '100',
                units: 'profiles',
            },
            {
                value: '365',
                units: 'days',
            },
        ],
    },
];

const GROOM_PRICING = [
    {
        price: '2600',
        label: 'Silver',
        paymentLink: 'https://www.instamojo.com/@marathimala/la9e1ab96c17e473f89656899cd1bfa2e/',
        features: [
            {
                value: '40',
                units: 'profiles',
            },
            {
                value: '180',
                units: 'days',
            },
        ],
    },
    {
        price: '3200',
        label: 'Gold',
        paymentLink: 'https://www.instamojo.com/@marathimala/l559cdcc247c648978fe0d675dc0bebcf/',
        features: [
            {
                value: '40',
                units: 'profiles',
            },
            {
                value: '270',
                units: 'days',
            },
        ],
    },
    {
        price: '4700',
        label: 'Platinum',
        paymentLink: 'https://www.instamojo.com/@marathimala/l675e2aa1f4dc47e19da07e7b21798dbf/',
        features: [
            {
                value: '100',
                units: 'profiles',
            },
            {
                value: '365',
                units: 'days',
            },
        ],
    },
];

const Upgrade = ({ authed }) => {
    const pricingData = authed && authed.authJWT && authed.authJWT.gender === 'Female' ? BRIDE_PRICING : GROOM_PRICING;
    return (
        <div>
            <div className="container main heading text-center">
                <h1>Upgrade to our premium plans!</h1>
                <p>Get a high chance to find your life partner through us.</p>
                {/* <div className="row">
                    <div className="alert alert-info">
                        <p>
                            Being premium members in marathimala gives you the power to download horoscope and contact any profile you are interested
                            in.
                        </p>
                    </div>
                </div> */}
            </div>
            {/* <Pricing data={pricingData} />
            <div className="container">
                <div className="row">
                    <p>
                        * Only 2 profiles contact can be viewed per day, it will get accumulated if you don&#39;t view. This is to prevent spamming
                        the profiles by so many calls.
                    </p>
                </div>
            </div> */}
            <div className="container text-center">
                <div className="row">
                    <div className="alert alert-success">
                        <p style={{ fontSize: 16 }}>
                            Being premium members in marathimala gives you the power to download horoscope and contact any profile you are interested
                            in.
                        </p>
                        <p style={{ fontSize: 16 }}>Contact us via WhatsApp to upgrade!</p>
                        <p>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://api.whatsapp.com/send?phone=917358008884&text=I%20like%20to%20become%20premium%20member%20in%20Marathimala"
                                className="btn btn-default btn-lg"
                            >
                                <i className="fa fa-whatsapp fa-lg" aria-hidden="true" />
                                &nbsp;73580 08884
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const reduxConnector = connect(state => ({
    authed: state.authed,
}));

export default reduxConnector(Upgrade);
