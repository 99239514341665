import React, { Component } from 'react';
import { connect } from 'react-redux';
import Glider from 'react-glider';
import 'glider-js/glider.min.css';

import { push } from '../utils/history';

import NavContainer from '../containers/NavContainer';
import Footer from './Footer';

import { CDN_URL } from '../constants/Constants';
import WhatsAppButton from './WhatsAppButton';

class Home extends Component {
    register = (e) => {
        e.preventDefault();
        push('/signup');
    };

    signIn = (e) => {
        e.preventDefault();
        push('/login');
    };

    myProfile = (e) => {
        e.preventDefault();
        push('/profile/edit/basic');
    };

    searchProfile = (e) => {
        e.preventDefault();
        push('/profile/search');
    };

    render() {
        const FEATURED_BRIDE = [
            {
                mid: 'MF3206',
                photo_url: '/profile-photo/small/5e3cf639e4c7550004c26b70-NArDglJOEY.jpg',
            },
            {
                mid: 'MF2411',
                photo_url: '/profile-photo/small/5bbb3c01574da90004025b66-CJXf9ANrFj.jpg',
            },
            {
                mid: 'MF2202',
                photo_url: '/profile-photo/small/5a946239db17ae00042cd238-8G4oLYlzTX.png',
            },
            {
                mid: 'MF2200',
                photo_url: '/profile-photo/small/5a93ee54db17ae00042cd231-VO6iB2ZYBr.jpg',
            },
            {
                mid: 'MF2162',
                photo_url: '/profile-photo/small/5a81d1249af40a000468e815-9eeTkh09DT.jpg',
            },
            {
                mid: 'MF1979',
                photo_url: '/profile-photo/small/5a3fb2fd58596c0004dff838-jrCK3QDGBe.jpg',
            },
        ];
        const FEATURED_GROOM = [
            {
                mid: 'MM3009',
                photo_url: '/profile-photo/small/5d4bb776d3e27c0004db150a-vRgGzNIGk2.jpg',
            },
            {
                mid: 'MM2576',
                photo_url: '/profile-photo/small/5c8f5662af004a00041592c9-vJmVMwjVmA.jpg',
            },
            {
                mid: 'MM1524',
                photo_url: '/profile-photo/small/5a16d44836dc8a00042073a3-edjUpZGTff.jpg',
            },
            {
                mid: 'MM1306',
                photo_url: '/profile-photo/small/59ac29cb6593070004ca7f09-YSQ3n267l1.jpg',
            },
            {
                mid: 'MM1514',
                photo_url: '/profile-photo/small/59b8191c12d3020004193ec3-WnQSK2wRC5.jpg',
            },
            {
                mid: 'MM1856',
                photo_url: '/profile-photo/small/5a16d44836dc8a00042073a3-edjUpZGTff.jpg',
            },
        ];
        const HAPPY_MARRIAGES = [
            {
                caption: 'Sathya Narayana Rao & Pavithra Bai',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Sathya Narayana Rao & Pavithra Bai.jpg',
            },
            {
                caption: 'Sathish Kumar & Pavithra Bai',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Sathish Kumar & Pavithra Bai.jpg',
            },
            {
                caption: 'Latha & Harish Rao',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Latha & harish rao.jpg',
            },
            {
                caption: 'Deepak & Griheetha',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Deepak & Griheetha.jpg',
            },
            {
                caption: 'Rajesh Rao & Shanthini',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Rajesh-Rao-Shanthini.jpeg',
            },
            // {
            //     caption: 'Raghavendra Rao & Likhitha Telkar',
            //     photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Raghavendra-Rao-Likhitha-Telkar.jpeg',
            // },
            {
                caption: 'Hema Ajay & Ranasubhe',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Hema-Ajay-Ranasubhe.jpeg',
            },
            {
                caption: 'Manoj & Arthi',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Manoj-Arthi.jpeg',
            },
            {
                caption: 'Sandeep & his better-half',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Sandeep.jpeg',
            },
            {
                caption: 'Anand Rao & Priyanka',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Anand-Rao-Priyanka.jpeg',
            },
            {
                caption: 'Sunil Avanthkar & Ashritha',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/SunilAvanthkar-Ashritha.jpeg',
            },
            {
                caption: 'Arun Manohar & Mridula',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/ArunManohar-Mridula.jpeg',
            },
            {
                caption: 'Shreekanth & Rakshitha',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Shreekanth-Rakshitha.jpeg',
            },
            {
                caption: 'Kaladhar',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Kaladhar.jpeg',
            },
            {
                caption: 'Kiran Mahendraker',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Kiran-Mahendraker.jpeg',
            },
            {
                caption: 'Yashwanth & Arthi Priya',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Yashwanth-ArthiPriya.jpeg',
            },
            {
                caption: 'Mithra & Sunil Kumar',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Mithra-SunilKumar.jpeg',
            },
            {
                caption: 'Yashwanth & Swapna',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Yashwanth-Swapna.jpeg',
            },
            {
                caption: 'RajVanthi & Hariharan',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/RajVanthi-Hariharan.jpg',
            },
            {
                caption: 'Nivetha & JainthKumar',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Nivetha-JainthKumar.jpg',
            },
            {
                caption: 'Manjula & SaranPradeep',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Manjula-SaranPradeep.jpg',
            },
            {
                caption: 'Nadhini & Kiran',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Nadhini-Kiran.jpg',
            },
            {
                caption: 'Sunitha & Karthik',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Karthik-Sunitha.jpg',
            },
            {
                caption: 'Surendranath & Kavitha',
                photo_url: 'http://assets.marathimala.com/assets/happy-marriages/Surendranath-Kavitha.jpg',
            },
        ];

        const { isLoggedIn, isMobile } = this.props;
        return (
            <div style={{ textAlign: 'center' }}>
                <NavContainer from="home" />
                <div className="jumbotron" style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <div className="jumbotron-content container-fluid">
                        <h2>Marathimala matrimony</h2>
                        <h2 style={{ marginBottom: '4rem' }}>An exclusive south-indian marathi matrimony.</h2>
                        {isLoggedIn ? (
                            <p>
                                <a className="btn btn-primary btn-lg" style={{ marginRight: 10 }} href="/" onClick={this.myProfile} role="button">
                                    My Profile
                                </a>
                                <a className="btn btn-info btn-lg" href="/" onClick={this.searchProfile} role="button">
                                    Search Profile
                                </a>
                            </p>
                        ) : (
                            <p>
                                <a className="btn cta-btn btn-lg" style={{ marginRight: 10 }} href="/" onClick={this.register} role="button">
                                    Register now!
                                </a>
                                <a className="btn cta-btn btn-lg" href="/" onClick={this.signIn} role="button">
                                    Login
                                </a>
                            </p>
                        )}
                    </div>
                </div>
                <div className="container">
                    <div
                        className="row"
                        style={{
                            marginBottom: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        <h2 className="section-header">
                            <span className="highlight-color">Featured</span> Bride
                        </h2>
                        {FEATURED_BRIDE.map((fb, i) => (
                            <div key={fb.mid} className="col-xs-6 col-md-2">
                                <a href="/" className="thumbnail">
                                    <div className="thumb-image">
                                        <img style={{ display: 'block' }} src={`${CDN_URL}${fb.photo_url}`} alt={fb.mid} />
                                    </div>
                                    <h6>{fb.mid}</h6>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div
                        className="row"
                        style={{
                            marginBottom: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        <h2 className="section-header">
                            <span className="highlight-color">Featured</span> Groom
                        </h2>
                        {FEATURED_GROOM.map((fg, i) => (
                            <div key={fg.mid} className="col-xs-6 col-md-2">
                                <a href="/" className="thumbnail">
                                    <div className="thumb-image">
                                        <img style={{ display: 'block' }} src={`${CDN_URL}${fg.photo_url}`} alt={fg.mid} />
                                    </div>
                                    <h6>{fg.mid}</h6>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="row" style={{ marginBottom: 40, paddingLeft: 10, paddingRight: 10 }}>
                        <h2 className="section-header">
                            <span className="highlight-color">Happy</span> Marriages
                        </h2>
                        <Glider
                            slidesToShow="auto"
                            slidesToScroll="auto"
                            duration={0.25}
                            responsive={[
                                {
                                    breakpoint: 775,
                                    settings: {
                                        slidesToShow: '3',
                                        slidesToScroll: '2',
                                    },
                                },
                            ]}
                            hasDots
                            hasArrows
                            draggable
                        >
                            {HAPPY_MARRIAGES.map((hm, i) => (
                                <div key={i}>
                                    <a href="/signup" className="thumbnail">
                                        <div className="thumb-image">
                                            <img style={{ display: 'block' }} src={`${hm.photo_url}`} alt={hm.caption} />
                                        </div>
                                        <h6>{hm.caption}</h6>
                                    </a>
                                </div>
                            ))}
                        </Glider>
                    </div>
                    <div className="row" style={{ marginBottom: 40 }}>
                        <WhatsAppButton />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect(state => ({
    isLoggedIn: state.authed.accessToken,
    isMobile: state.environment.isMobile,
}))(Home);
