import React from 'react';
// import upi from '../assets/images/upi-payment.jpg';
// import qrcode from '../assets/images/marathimala-qr-code.jpg';
import { connect } from 'react-redux';

const Forbidden = (props) => {
    const { username, mid } = props;
    return (
        <div>
            <div className="container main heading text-center">
                <h1>Congratulations!</h1>
                <p>Your profile has been successfully registered with us.</p>
                <div className="row">
                    <div className="alert alert-info">
                        <p>
                            We are in the process of verifying your profile
                            details. Generally, verification may take a day or
                            two, so please hang-on.
                        </p>
                    </div>
                </div>
            </div>
            {/* <div id="generic_price_table">
            <section>
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <div className="generic_content clearfix active">
                            <div className="generic_head_price clearfix">
                                <div className="generic_head_content clearfix">
                                    <div className="head_bg" />
                                    <div className="head">
                                        <span>Registration Fee</span>
                                    </div>
                                </div>

                                <div className="generic_price_tag clearfix">
                                    <span className="price">
                                        <span className="sign">₹</span>
                                        <span className="currency">350</span>
                                    </span>
                                </div>
                            </div>

                            <div className="generic_feature_list">
                                <ul>
                                    <li>Your profile will be displayed on the website</li>
                                    <li>You can search for Bride / Groom (without contact number, horoscope)</li>
                                    <li>Your profile will listed while other people search</li>
                                </ul>
                            </div>

                            <div className="generic_price_btn clearfix">
                                <span>Pay Now via</span>
                            </div>
                            <div className="generic_feature_list">
                                <ul>
                                    <li>
                                        <img src={upi} alt="Pay via Google Pay, Phonepe, BHIM or Whatsapp" />
                                    </li>
                                    <li>
                                        <span style={{ fontSize: 24, fontWeight: 600 }}>marathimala@oksbi</span>
                                    </li>
                                    <li>
                                        <img src={qrcode} alt="Scan QR Code" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div> */}
            <div className="container text-center">
                <div className="row">
                    <div className="alert alert-success">
                        <p>
                            Click the button below and Message us on WhatsApp to
                            approve your profile{' '}
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://api.whatsapp.com/send?phone=917358008884&text=Hi%20Marathimala%2C%20Help%20approve%20my%20profile%20${username}%20(${mid}).%20Thank%20you.`}
                                className="btn btn-default btn-lg"
                            >
                                <i
                                    className="fa fa-whatsapp fa-lg"
                                    aria-hidden="true"
                                />
                                &nbsp;73580 08884
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const reduxConnector = connect((state) => ({
    username: state.authed.authJWT.user_name,
    mid: state.authed.authJWT.mid,
}));

export default reduxConnector(Forbidden);
