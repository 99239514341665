import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Router } from 'react-router-dom';
import history from './utils/history';
import App from './containers/App';
import './index.css';
import configureStore from './store/configureStore';

const store = configureStore();

console.log(process.env.PUBLIC_URL);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
        <ReduxToastr
            timeOut={4000}
            newestOnTop
            preventDuplicates
            position="top-right"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
            progressBar
        />
    </Provider>,
    document.getElementById('root'),
);
