import React from 'react'

const WhatsAppButton = () => (
    <div className="whatsapp-btn-wrapper">
        <h3>Register via Whatsapp</h3>
        <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://api.whatsapp.com/send?phone=917358008884&text=Hi%20Marathimala%2C%20Help%20us%20register%20our%20details.%20Thank%20you."
        >
            <i className="fa fa-whatsapp fa-lg" aria-hidden="true" />
                &nbsp;73580 08884
        </a>
    </div>
);

export default WhatsAppButton;
