import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { toastr } from 'react-redux-toastr';
import { EventTypes } from 'redux-segment';
import { push } from '../utils/history';
import { initEnvironment } from './EnvironmentActions';

import * as fetchUtils from '../utils/FetchUtils';
import * as types from '../constants/ActionTypes';

const COOKIE_PATH = 'accessTokenV2';

export function initAuth() {
    return (dispatch) => {
        const accessToken = Cookies.get(COOKIE_PATH);
        if (accessToken) {
            return dispatch(authUser(accessToken));
        }
        return dispatch({ type: types.CHECKED_AUTHED, isAuthChecked: true });
    };
}

function mapChangePasswordFormData(formData) {
    return {
        old_password: formData.oldPassword,
        password: formData.password,
    };
}

export function changePassword(formData) {
    return (dispatch) => {
        fetch(
            `${process.env.PUBLIC_URL}/api/password/change`,
            fetchUtils.setPostHeaders(mapChangePasswordFormData(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((data) => {
                if (data.Status !== 'Success') {
                    toastr.error(
                        'Password',
                        "Couldn't able to change password...",
                    );
                } else {
                    toastr.success(
                        'Password',
                        'Your password has been successfully updated...',
                    );
                }
                return data;
            })
            .then((data) => {
                push('/');
            })
            .catch((ex) => {
                toastr.error('Password', "Couldn't able to change password...");
                console.log('parsing failed', ex);
            });
    };
}

function mapResetPasswordFormData(formData) {
    const date_of_birth = [
        formData.dob_year,
        formData.dob_month,
        formData.dob_date,
    ].join('-');
    return {
        mobile_no: formData.mobileNo,
        date_of_birth,
        domain: 'marathimala',
    };
}

export function resetPassword(formData) {
    return () => {
        fetch(
            `${process.env.PUBLIC_URL}/api/password/reset`,
            fetchUtils.setPostHeaders(mapResetPasswordFormData(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((data) => {
                if (data.Status !== 'Success') {
                    toastr.error(
                        'Password',
                        "Couldn't able to reset password...",
                    );
                } else if (data.Message === 'No Email provided') {
                    toastr.message(
                        'Reset Password',
                        'Please send your email address to 7358008884 (WhatsApp)',
                    );
                } else {
                    toastr.message(
                        'Reset Password',
                        'Link to reset password has been sent to you email!',
                    );
                }
                return data;
            })
            .then(() => {
                push('/login');
            })
            .catch(() => {
                toastr.error('Password', "Couldn't able to reset password...");
            });
    };
}

export function verifyEmail(data) {
    return (dispatch) => {
        fetch(
            `${process.env.PUBLIC_URL}/api/user/verify/email`,
            fetchUtils.setPostHeaders(data),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((data) => {
                dispatch({
                    type: types.VERIFY_EMAIL,
                    isEmailVerified: data.Status === 'Success',
                });
            })
            .catch((ex) => {
                fetchUtils.handleError('Login failed', ex);
            });
    };
}

export function refreshToken() {
    return (dispatch) => {
        fetch(
            `${process.env.PUBLIC_URL}/api/refresh-token`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return dispatch(authUser(data.token));
            })
            .catch((ex) => {
                console.log('RefreshToken failed', ex);
            });
    };
}

function mapLoginFormData(formData) {
    return {
        mobile_no: formData.mobileNo,
        password: formData.password,
    };
}

export function loginUser(formData, nextUrl) {
    return (dispatch) =>
        fetch(
            `${process.env.PUBLIC_URL}/api/login`,
            fetchUtils.setPostHeaders(mapLoginFormData(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return dispatch(authUser(data.token));
            })
            .then(() => {
                if (nextUrl) {
                    console.log(nextUrl);
                    return push(nextUrl);
                }
                return push('/profile/edit/basic');
            })
            .catch((ex) => {
                fetchUtils.handleError('Login failed', ex);
            });
}

export function signupUser(formData) {
    return (dispatch) => {
        fetch(
            `${process.env.PUBLIC_URL}/api/signup`,
            fetchUtils.setPostHeaders(mapSignupFormData(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return data;
            })
            .then((data) => dispatch(authUser(data.token)))
            .then((data) => {
                push('/profile/register/basic');
                try {
                    if (window && window.gtag) {
                        window.gtag('event', 'conversion', {
                            send_to: 'AW-617521006/FphTCL7ZndwBEO6-uqYC',
                        });
                    }
                } catch (err) {}
            })
            .catch((ex) => {
                fetchUtils.handleError('Something went wrong!', ex);
            });
    };
}

function mapSignupFormData(formData) {
    return {
        user_name: formData.name,
        mobile_no: formData.mobileNo,
        password: formData.password,
        domain: formData.domain || 'marathimala',
    };
}

export function logoutUser() {
    return (dispatch, getState) => {
        Cookies.remove(COOKIE_PATH);
        dispatch(resetAuthed());
        dispatch(initEnvironment());
        dispatch(initAuth());
        toastr.info('See you soon!', 'You have logged out successfully...');
    };
}

function authUser(accessToken) {
    return (dispatch) => {
        try {
            const authJWT = jwtDecode(accessToken);
            const { mid, user_name: userName, user_id: userId } = authJWT;
            const userData = {
                distinct_id: userId,
                name: `${userName}(${mid})`,
            };
            if (window.tidioChatApi) {
                window.tidioChatApi.setVisitorData(userData);
            } else {
                document.tidioIdentify = userData;
            }
        } catch (ex) {
            console.warn(ex.message);
        }
        return dispatch(fetchAuthedUser(accessToken));
    };
}

function fetchAuthedUser(accessToken) {
    return (dispatch) =>
        fetch(`${process.env.PUBLIC_URL}/api/me`, fetchUtils.setGetHeaders())
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((u) => dispatch(receiveAuthedUserPre(accessToken, u)))
            .catch((err) => {
                dispatch({ type: types.CHECKED_AUTHED, isAuthChecked: true });
                throw err;
            });
}

function receiveAuthedUserPre(accessToken, user) {
    return (dispatch) => {
        dispatch(receiveAccessToken(accessToken));
        dispatch(receiveAuthedUser(user));
    };
}

function resetAuthed() {
    return {
        type: types.RESET_AUTHED,
    };
}

function receiveAccessToken(accessToken) {
    return {
        type: types.RECEIVE_ACCESS_TOKEN,
        accessToken,
    };
}

function receiveAuthedUser(user) {
    return {
        type: types.RECEIVE_AUTHED_USER,
        user,
        meta: {
            analytics: {
                eventType: EventTypes.identify,
                eventPayload: {
                    userId: user.user_id,
                },
            },
        },
    };
}
