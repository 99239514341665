import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { Link } from '../utils/Link';
import { push } from '../utils/history';
import { renderField } from '../utils/ReduxFormUtils';

export const validate = (values) => {
    const errors = {};
    if (!values.mobileNo) {
        errors.mobileNo = 'Required';
    }
    // if (values.mobileNo && !/^\d{10}$/.test(values.mobileNo)) {
    //     errors.mobileNo = 'Invalid mobile number'
    // }
    if (!values.password) {
        errors.password = 'Required';
    }
    return errors;
};

class Login extends Component {
    componentDidMount() {
        const { dispatch, isLoggedIn } = this.props;
        const { from } = this.props.location.state || {
            from: { pathname: '/profile/search' },
        };
        if (isLoggedIn) {
            dispatch(push(from));
        }
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        const { from } = this.props.location.state || {
            from: { pathname: '/profile/search' },
        };

        return (
            <div>
                <div className="container main heading text-center">
                    <h1>Login</h1>
                    <p>
                        Don't have an account? <Link to="/signup">Sign Up</Link>
                    </p>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 col-md-offset-4 col-sm-offset-3">
                            <form onSubmit={handleSubmit(data => this.props.onSubmit({ ...data }, from))} autoComplete="off">
                                {/* normalize={normalizePhone} */}
                                <Field
                                    name="mobileNo"
                                    type="text"
                                    className="form-control"
                                    component={renderField}
                                    label="Mobile"
                                    placeholder="Your mobile number"
                                />
                                <Field
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    component={renderField}
                                    label="Password"
                                    placeholder="Your password"
                                />
                                <div className="form-group">
                                    <button type="submit" className="btn btn-lg btn-wide btn-success" disabled={submitting}>
                                        {submitting ? 'Logging in...' : 'Login'}
                                    </button>
                                </div>
                                <p className="text-center">
                                    <a href="/password/reset">
                                        Forgot password?
                                    </a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Decorate the form component
const reduxFormDecorator = reduxForm({
    form: 'login',
    validate,
});

// You have to connect() to any reducers that you wish to connect to yourself
const reduxConnector = connect(state => ({
    isLoggedIn: state.authed.accessToken,
}));

export default reduxConnector(reduxFormDecorator(Login));
