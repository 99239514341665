import React, {
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import * as fetchUtils from '../utils/FetchUtils';

const PublicProfileSearch = () => {
    const [profiles, setProfiles] = useState([]);
    const [message, setMessage] = useState('');

    const formRef = useRef(null);

    const handleSubmit = async (params) => {
        const { dateOfBirth: dob, domain = 'marathimala' } = params;

        if (!dob) return;

        const dateOfBirth = dob
            .split('-')
            // eslint-disable-next-line arrow-parens
            .map((v) => Number(v))
            .join('-');

        const host = process.env.PUBLIC_URL;
        const url = `${host}/api/public-profile`;
        const config = {
            method: 'POST',
            body: JSON.stringify({ date_of_birth: dateOfBirth, domain }),
        };
        try {
            const p = await fetch(url, config)
                .then(fetchUtils.checkStatus)
                .then(fetchUtils.parseJSON);
            setProfiles(p || []);
        } catch (error) {
            fetchUtils.handleError('Error', error);
        }
    };

    const handleReset = () => {
        setMessage('Please select a Date of birth.');
    };

    useEffect(() => {
        if (profiles.length < 1) {
            const elements = Array.from(formRef.current.elements);
            const values = elements.reduce((acc, curr) => {
                if (curr.name) {
                    return { ...acc, [curr.name]: curr.value };
                }
                return acc;
            }, {});
            if (!values.dateOfBirth) {
                setMessage('Please select a Date of birth.');
                return;
            }
            setMessage('No profiles match for this date of birth!');
            return;
        }
        setMessage('');
    }, [profiles]);

    return (
        <div className="container">
            <Form ref={formRef} onReset={handleReset} onSubmit={handleSubmit} />
            <table>
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Gender</th>
                        <th>City</th>
                    </tr>
                </thead>
                <tbody>
                    {profiles.map((p, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={`profile-${i}`}>
                            <td>{i + 1}</td>
                            <td>{p.name}</td>
                            <td>{p.gender}</td>
                            <td>{p.city || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {message ? <p className="msg text-center">{message}</p> : null}
            <style jsx>
                {`
                    table {
                        border-collapse: collapse;
                        width: 100%;
                        border: 1px solid #ddd;
                    }

                    thead tr {
                        background-color: #ededed;
                        letter-spacing: 0.1rem;
                    }

                    tbody tr:hover {
                        background-color: #00000005;
                    }

                    th,
                    td {
                        text-align: left;
                        padding: 1rem;
                        font-size: 1.5rem;
                    }

                    .msg {
                        font-size: 1.5rem;
                        padding: 2rem 0;
                    }
                `}
            </style>
        </div>
    );
};

const Form = forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { onSubmit, onReset } = props;

    const handleSubmit = useCallback((evt) => {
        evt.preventDefault();
        const elements = Array.from(ref.current.elements);
        const values = elements.reduce((acc, curr) => {
            if (curr.name) {
                return { ...acc, [curr.name]: curr.value };
            }
            return acc;
        }, {});
        onSubmit(values);
    }, []);

    return (
        <form ref={ref} onSubmit={handleSubmit} onReset={onReset}>
            <div className="form-fields">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <input
                    id="dateOfBirth"
                    type="date"
                    required
                    name="dateOfBirth"
                    className="form-control"
                    title="Date of Birth"
                />
            </div>
            <button
                className="btn btn-primary"
                type="submit"
                title="Get Profiles"
            >
                Get Profiles
            </button>
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="btn" type="reset" title="Reset">
                Reset
            </button>
            <style jsx>
                {`
                    form {
                        display: flex;
                        gap: 2rem;
                        align-items: flex-end;
                        margin-bottom: 2rem;
                    }

                    .form-fields {
                        width: 100%;
                        max-width: 20rem;
                    }

                    label {
                        font-weight: 600;
                        font-size: smaller;
                    }

                    button {
                        height: 38px;
                    }
                `}
            </style>
        </form>
    );
});

export default PublicProfileSearch;
