import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';

import Checkbox from '../utils/Checkbox';
import { updateProfileListFilter } from '../actions/ProfileActions';
import {
    MARITAL_STATUS,
    EDUCATION,
    INCOME,
    STAR,
    STATE,
    COMMUNITY,
    STATE_FILTER_PREF,
} from '../constants/Constants';

// import '../assets/css/common.css';
import '../assets/css/list.css';

class ProfileListFilter extends Component {
    constructor(props) {
        super(props);
        const { authJWT: { state: s } = {} } = props;
        this.state = {
            stateFilter: STATE_FILTER_PREF.find((f) => f.state === s)
                ? [...STATE_FILTER_PREF.find((f) => f.state === s).pref]
                : [...STATE],
        };
    }

    onAgeChange = (e) => {
        this.handleFilterChange(e.target.name, e.target.value, true, false);
    };

    onNameChange = (e) => {
        this.handleFilterChange(e.target.name, e.target.value, true, false);
    };

    handleFilterChange = (n, v, c, m) => {
        const { updateFilter } = this.props;
        updateFilter(n, v, c, m);
    };

    render() {
        const age = [...Array(70).keys()].filter((i) => i > 17);
        const { data, authJWT: { role = 'USER' } = {} } = this.props;
        const { stateFilter } = this.state;
        const fromAge = find(data, { name: 'from_age' }).value;
        const toAge = find(data, { name: 'to_age' }).value;
        const isAdmin = role === 'ADMIN';

        return (
            <div className="refine-search">
                <h4>Filter</h4>
                {isAdmin && (
                    <>
                        <div className="marb10">
                            <h5>Name</h5>
                        </div>
                        <div className="px-10">
                            <div className="form-group">
                                <input
                                    name="name"
                                    onBlur={this.onNameChange}
                                    className="form-control"
                                    placeholder="Name"
                                    type="text"
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="">
                    <h5>
                        <Checkbox
                            value="1"
                            isGroup={false}
                            label="View Shortlisted"
                            style={{ margin: 0 }}
                            name="shortlist"
                            handleCheckboxChange={this.handleFilterChange}
                        />
                    </h5>
                </div>
                <div className="">
                    <h5>Age</h5>
                    <div className="row">
                        <div className="col-md-6 col-xs-6 marb10">
                            <div
                                className="form-group"
                                style={{ paddingLeft: 20, marginTop: 5 }}
                            >
                                <label
                                    htmlFor="from_age"
                                    className="control-label"
                                >
                                    From
                                </label>
                                <select
                                    name="from_age"
                                    style={{ width: 60 }}
                                    onChange={this.onAgeChange}
                                    value={fromAge}
                                    className="form-control"
                                >
                                    {age.map((a, i) => (
                                        <option key={i} value={a}>
                                            {a}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-6 marb10">
                            <div
                                className="form-group"
                                style={{ paddingLeft: 20, marginTop: 5 }}
                            >
                                <label
                                    htmlFor="to_age"
                                    className="control-label"
                                >
                                    To
                                </label>
                                <select
                                    name="to_age"
                                    style={{ width: 60 }}
                                    onChange={this.onAgeChange}
                                    value={toAge}
                                    className="form-control"
                                >
                                    {age.map((a, i) => (
                                        <option key={i} value={a}>
                                            {a}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <h5>Community</h5>
                    <ul className="list-unstyled">
                        {COMMUNITY.map((c) => (
                            <li key={c.value}>
                                <Checkbox
                                    value={c.value}
                                    label={c.label}
                                    name="community"
                                    handleCheckboxChange={
                                        this.handleFilterChange
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="">
                    <h5>State</h5>
                    <ul className="list-unstyled">
                        {stateFilter &&
                            stateFilter.map((s) => (
                                <li key={s}>
                                    <Checkbox
                                        value={s}
                                        label={s}
                                        name="state"
                                        handleCheckboxChange={
                                            this.handleFilterChange
                                        }
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="">
                    <h5>Education</h5>
                    <ul className="list-unstyled">
                        {EDUCATION.map((c) => (
                            <li key={c}>
                                <Checkbox
                                    value={c}
                                    label={c}
                                    name="education"
                                    handleCheckboxChange={
                                        this.handleFilterChange
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="">
                    <h5>Annual Income</h5>
                    <ul className="list-unstyled">
                        {INCOME.map((c) => (
                            <li key={c}>
                                <Checkbox
                                    value={c}
                                    label={c}
                                    name="annual_income"
                                    handleCheckboxChange={
                                        this.handleFilterChange
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="">
                    <h5>Marital Status</h5>
                    <ul className="list-unstyled">
                        {MARITAL_STATUS.map((c) => (
                            <li key={c}>
                                <Checkbox
                                    value={c}
                                    label={c}
                                    name="martial_status"
                                    handleCheckboxChange={
                                        this.handleFilterChange
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="">
                    <h5>Birth Star</h5>
                    <ul className="list-unstyled">
                        {STAR.map((c) => (
                            <li key={c.t}>
                                <Checkbox
                                    value={c.t}
                                    label={`${c.t} (${c.e})`}
                                    name="birth_star"
                                    handleCheckboxChange={
                                        this.handleFilterChange
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFilter: (n, v, c, m) => {
        dispatch(updateProfileListFilter(n, v, c, m));
    },
});
const mapStateToProps = (state) => ({
    authJWT: state.authed.authJWT,
});
const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps,
);
export default reduxConnector(ProfileListFilter);
